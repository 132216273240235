.hero-banner {
    background-image:url('../images/bg.jpg');
    background-size:cover;
    background-position:center center;
    margin:0;
    border:0;
    margin-top:0px;
}

.property-details {
    background:#223976;
    margin:0;
}

.property-features {
    background: rgba(0,0,0,0.1);
}

.property-details .row, .property-features .row {
    max-width: 1100px;
    padding:75px 0;
    margin:0px auto;
}

.exterior-image {
    max-width:100%;
}

.property-details .property p {
    margin:0 15px;
    padding:20px;
    background:rgba(255,255,255,0.9);
    text-align:center;
    font-size:16px;
}

.property-details .property h2 {
    padding:20px 0 0 0;
    font-weight:800;
}

.hero-logo {
    max-width:350px;
    margin:50px 0;
}

h1, h2, h3, h4, h5 {
    font-family: "Merriweather", serif;
    font-weight: 400;
    font-style: normal;
}

.hero-banner h1, .hero-banner h2 {
    color:rgba(0,0,0,0.75);
    text-shadow:5px 5px rgba(0,0,0,.1);
    margin:25px 0;
}

p.lead {
    color:rgba(0,0,0,0.75);
    font-size:24px;
}

.edgelogo {
    max-width:175px;
    margin-right:25px;
}

.property-gallery .image-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; /* Adjust spacing around items */
    padding: 10px;
}
    
.property-gallery .image-container {
    flex: 1 1 20%; /* Adjust basis to control number of columns */
    margin: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Optional: adds shadow for depth */
    border-radius: 5px; /* Optional: adds rounded corners */
    overflow: hidden; /* Ensures the border-radius clips the image */
}
    
.property-gallery .image-container img {
    width: 100%;
    height: auto;
    display: block; /* Removes bottom margin/gap */
}

.property-gallery .row {
    max-width:1200px;
    margin:0 auto;
    padding:75px 0;
}

.contact-details .row {
    padding:50px 0;
    background:#D2DDDF;
    text-align:center;
    border-top:2px solid #093479;
}

.contact-details .row h3 {
    font-family:"roboto",sans-serif;
    color:#000;
}